import React from 'react';
import { StyledModal,StyledTitle, StyledBody, } from '../../components/EngagementSectionV2/CompanyInitiative/./styles';
import { Modal } from "react-bootstrap";
import PropTypes from 'prop-types'; 
import { closeIcon, CongratulationsEmojiIcon, TickIcon } from '../../utils/icons';
import { FlexContainer, FlexDiv, LeftText, RightText } from './styles';

function PointsPopup({showModal, onClose,quizResponse,setActiveTab}) {


  const onLeaderboardRedirection = () => {
    setActiveTab(2)
    onClose()
  };
  


  return (
    <StyledModal
      transform={1}
      show={showModal}
      newWidth={"800px"}
      newHeight={"524px"}
      newBg={"newBg"}
      height="50px"
      display="none"
    >
      <Modal.Header
        closeButton
        onHide={onClose}
        style={{ background: "white" }}
      >
        <StyledTitle style={{ color: "#005C87", width:"100%", fontSize: "18px",display:"flex",alignItemsL:"center", justifyContent:"center" }}>
        
          <div style={{color: "#005C87", width:"100%", fontSize: "18px",display:"flex",alignItemsL:"center", justifyContent:"center"}}>
            {TickIcon()} &nbsp; Great job!
          </div>
          <div onClick={onClose} style={{ marginLeft: "auto", cursor: "pointer" }}>
            {closeIcon()} 
          </div>
          <div onClick={() => onClose()} style={{display:"flex", margin:"auto 0 auto auto", cursor:"pointer"}}>
          </div> 

        </StyledTitle>
      </Modal.Header>
      <StyledBody style={{padding: "24px 24px 16px 24px"}}>
        <div style={{display:"flex",justifyContent:"center",color:"#005C87", fontFamily:"Rubik",fontSize:"18px",fontWeight:"bold"}}>Congratulations! &nbsp; {CongratulationsEmojiIcon()}</div>
        {/* <div style={{display:"flex",justifyContent:"center",color:"#005C87", fontFamily:"Rubik",fontSize:"18px"}} >You have completed the quiz & earned +500 points</div> */}
        {quizResponse?.points == null  || quizResponse?.points === 0 ? (
          <div style={{ display: "flex", justifyContent: "center", color: "#005C87", fontFamily: "Rubik", fontSize: "18px" }}>
            You have completed the quiz!
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center", color: "#005C87", fontFamily: "Rubik", fontSize: "18px" }}>
            You have completed the quiz & earned +{quizResponse?.points} points
          </div>
        )}
        <FlexContainer>
          <FlexDiv>
            <LeftText>Correct Answers</LeftText>
            <RightText>{quizResponse?.correct_answers} Questions</RightText>  
          </FlexDiv>

          <FlexDiv bgColor={"rgba(244, 170, 169, 0.15)"}>
            <LeftText>Incorrect Answers</LeftText>
            <RightText>{quizResponse?.not_correct_answers} Questions</RightText> 
          </FlexDiv>

          <FlexDiv bgColor={"rgba(241, 201, 119, 0.15);"}>
            <LeftText>Unanswered</LeftText>
            <RightText>{quizResponse?.un_answered} Questions</RightText>
          </FlexDiv>
        </FlexContainer>
        <div style={{display:"flex",textAlign:"center",margin:"24px auto 0px auto",justifyContent:"center",color:"#005C87", fontFamily:"Rubik",fontSize:"18px",width: '490px'}} >Check back tomorrow for the final results and find out where you stand on the leaderboard.</div>
        <div onClick={onLeaderboardRedirection} style={{display:"flex",width:"364px",justifyContent:"center",alignItems:"center",height: "48px",padding: "4px",gap: "4px",background: "#007AB1",flexShrink: "0",borderRadius:"4px", color:"white",margin:"10px auto 10px auto",cursor:"pointer"}}>Show Leaderboard</div>
      </StyledBody>
  
    
    </StyledModal>
  );
}


PointsPopup.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  quiz_Point: PropTypes.number,
  quizResponse: PropTypes.number,
  setActiveTab:PropTypes.func.isRequired,
};
  
export default PointsPopup;
